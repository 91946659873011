import styles from './Album.styles.module.css';
import client, { urlFor } from '../../sanityClient';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Card, CardMedia, Grid } from '@mui/material';
import { useMediaQuery } from '@mui/material';

interface AlbumProps {
    _id: string;
    description: string;
    albumCover: { asset: { _ref: string } };
    title: string;
}

const Album = () => {
    const isHorizontal = useMediaQuery('(max-height:1024px)');
    const [albums, setAlbums] = useState([]);

    useEffect(() => {
        client
            .fetch('*[_type == "album"]')
            .then((data) => {
                setAlbums(data);
            })
            .catch((error) => console.error('Error fetching photos:', error));
    }, []);

    return (
        <div className={styles.container}>
            {albums.map((album: AlbumProps) => (
                <Link
                    className={styles.albumContainer}
                    to={`/portfolio/${album.title}`}
                    key={album._id}
                >
                    <Card>
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    sm: 'row',
                                    xs: 'column',
                                },
                                justifyContent: 'center',
                            }}
                        >
                            {/* Album text */}
                            <Grid
                                item
                                xs={1}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: {
                                        md: '40vw',
                                        sm: '112vw',
                                        xs: '80vw',
                                    },
                                }}
                            >
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize: { xs: '2rem', sm: '3rem' },
                                        paddingTop: '1rem',
                                        wordBreak: 'break-word',
                                        hyphens: 'manual',  
                                    }}
                                >
                                    {album.title}
                                </Typography>
                                <Typography
                                    className={styles.description}
                                    variant="body2"
                                    sx={{
                                        fontSize: { xs: '1rem', sm: '1.5rem' },
                                        lineHeight: { xs: '1rem', sm: '2rem' },
                                        padding: { xs: '1rem', sm: '2rem' },
                                        marginBottom: {
                                            xs: '1rem',
                                            sm: '2rem',
                                        },
                                        wordBreak: 'break-word',
                                        hyphens: 'manual',
                                    }}
                                >
                                    {album.description}
                                </Typography>
                            </Grid>
                            {/* Foto */}
                            <Grid
                                item
                                display={'flex'}
                                justifyContent={'center'}
                            >
                                <Card>
                                    <CardMedia
                                        component="img"
                                        alt={album.title}
                                        image={`${urlFor(
                                            album.albumCover.asset._ref,
                                        )}?w=800&h=800&auto=format&dpr=2`}
                                        loading="lazy"
                                        sx={{
                                            height: {
                                                xs: '350px',
                                                sm: '500px',
                                                md: '500px',
                                            },
                                            width: {
                                                xs: '345px',
                                                sm: isHorizontal ? '850px' : '700px',
                                                md: '700px',
                                            },
                                        }}
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                    </Card>
                </Link>
            ))}
        </div>
    );
};

export default Album;
