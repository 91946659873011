import { AppBar, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/PicPeterLogo.svg';
import styles from './NavBar.styles.module.css';
import { navLinks } from '../../constants';

const Navbar = () => {
    const location = useLocation();
    const isSmallScreen = useMediaQuery('(max-width: 600px)');

    return (
        <AppBar
            position="static"
            className={styles.container}
            role="navigation"
        >
            <Toolbar
                style={{
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--background-color)',
                }}
            >
                <Link to="/">
                    <img
                        className={styles.logo}
                        alt="pic peter logo"
                        src={logo}
                    />
                </Link>
                <div className={styles.linkContainer}>
                    {navLinks.map((nav) => (
                        <div key={nav.id} className={styles.linkBox}>
                            <Link
                                to={`/${nav.id}`}
                                className={
                                    location.pathname === `/${nav.id}`
                                        ? `${styles.link} ${styles.activeLink}`
                                        : styles.link
                                }
                            >
                                <Typography
                                    variant="h1"
                                    sx={{
                                        fontSize: { xs: '1rem' },
                                    }}
                                >
                                    {nav.title}
                                </Typography>
                            </Link>
                        </div>
                    ))}
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
