import styles from './About.styles.module.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Card, CardMedia, Typography, IconButton, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import client, { urlFor } from '../../sanityClient';



interface ProfileInfoProps {
    title: string;
    description: string;
    image: { asset: { _ref: string } };
    email: string;
    socialMedia: {
        _type: string;
        socialMedia: { name: string; url: string }[]
    };
}


const About = () => {
    const [profileInfo, setProfileInfo] = useState<ProfileInfoProps[]>([]);


    useEffect(() => {
        client.fetch(`*[_type == "profile"]{
            title,
            description,
            image,
            email,
            socialMedia,
            }`).then((data) => {
            setProfileInfo(data);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container className={styles.container}>
            {/* Page title */}
            <Grid item xs={12}>
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: { xs: '2rem', sm: '3rem' },
                        color: 'var(--text-color)',
                        textAlign: { xs: 'center' },
                    }}
                >
                    About
                </Typography>
            </Grid>
            {/* Page Info  */}
            <Grid item>
                {/* Image */}
                <Grid item>
                    <Card
                        sx={{
                            boxShadow: 0,
                            marginBottom: '2rem',
                        }}
                    >
                        {profileInfo && profileInfo.length > 0 && (
                            <CardMedia
                                component="img"
                                alt={profileInfo[0]?.title}
                                sx={{
                                    objectFit: 'contain',
                                    height: '300px',
                                }}
                                src={urlFor(profileInfo[0]?.image.asset._ref).url()}
                                loading="lazy"
                            />
                        )}
                    </Card>
                </Grid>
                {/* Info */}
                <Grid
                    item
                    sx={{
                        marginBottom: '2rem',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: {
                                xs: '1rem',
                                sm: '1.5rem',
                                marginBottom: '1rem',
                            },
                        }}
                    >
                        {profileInfo[0]?.description}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: { xs: '1rem', sm: '1.5rem' },
                            marginBottom: '1rem',
                        }}
                    >
                        Work inquiries are welcome on
                        <br />
                        Instagram or via e-Mail:
                        <br />
                        {profileInfo[0]?.email}
                    </Typography>
                </Grid>
                {/* Instagram */}
                <Grid item sx={{ marginBottom: '6rem' }}>
                    {profileInfo[0]?.socialMedia.socialMedia.map(
                        (socialMediaLink: { name: string; url: string }) => (
                            <Typography
                                variant="body2"
                                sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }}
                                key={socialMediaLink.url}>
                                {socialMediaLink.name}
                                <IconButton
                                    href={socialMediaLink.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={styles.socialMediaLink}
                                >
                                    <InstagramIcon
                                        sx={{
                                            fontSize: 40,
                                            color: 'var(--text-color)',
                                        }}
                                    />
                                </IconButton>
                            </Typography>
                        )
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default About;