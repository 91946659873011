import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

const client = createClient({
    projectId: process.env.REACT_APP_PROJECT_ID,
    apiVersion: '2021-08-31',
    dataset: process.env.REACT_APP_DATASET,
    useCdn: true,
});

const builder = imageUrlBuilder(client);

export function urlFor(source: SanityImageSource) {
    return builder.image(source);
}

export default client;
