import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';
import styles from './Footer.styles.module.css';

const Footer = () => {
    const isMobile = useMediaQuery('(max-width:400px)');
    return (
        <BottomNavigation
            className={styles.container}
            role="contentinfo"
            sx={{
                height: isMobile ? "12vh" : "8vh",
                alignItems: 'center',
                justifyContent: 'space-around'
            }}
        >
            <Typography
                variant="h6"
                className={styles.madeBy}
                sx={{ md: { marginLeft: '30px' } }}
            >
                © 2023 Peter Eisenschmidt
            </Typography>
            <Typography
                variant="h6"
                className={styles.madeBy}
                sx={{ md: { marginRight: '30px' } }}
            >
                Made by{' '}
                <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/in/fridavbg/"
                >
                    Frida
                </Link>
            </Typography>
        </BottomNavigation>
    );
};

export default Footer;
