import styles from './PhotoList.styles.module.css';
import client, { urlFor } from '../../sanityClient';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, ModalContent } from './Modal';
import {
    Typography,
    Card,
    CardMedia,
    Stack,
    ImageList,
    ImageListItem,
    Button,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

interface PhotoProps {
    _id: string;
    description: string;
    image: { asset: { _ref: string } };
    title: string;
}

const PhotoList = () => {
    const { albumTitle } = useParams();
    const [photos, setPhotos] = useState<PhotoProps[]>([]);
    const [showPhoto, setShowPhoto] = useState<{ [key: string]: boolean }>({});
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        client
            .fetch(
                `*[_type == "album" && title == $albumTitle]{
                    title,
                    description,
                    "photos": photos[]->{_id, description, image, title}
                }[0]`,
                { albumTitle }
            )
            .then((data) => {
                setPhotos(data?.photos || []);
            })
            .catch((error) => console.error('Error fetching photos:', error));
    }, [albumTitle]);

    const toggleModal = (photoId: string) => {
        const index = photos.map((e) => e._id).indexOf(photoId);
        setCurrentPhotoIndex(index);
        setShowPhoto((prevStates) => ({
            ...prevStates,
            [photoId]: !prevStates[photoId],
        }));
    };

    function onPrev(): void {
        const newIndex =
            (currentPhotoIndex - 1 + photos.length) % photos.length;
        setCurrentPhotoIndex(newIndex);
    }

    function onNext(): void {
        const newIndex = (currentPhotoIndex + 1) % photos.length;
        setCurrentPhotoIndex(newIndex);
    }

    return (
        <Stack spacing={4} className={styles.container}>
            <ImageList
                sx={{ width: '98%', height: '100%', }}
                cols={3}
                gap={8}
                variant="masonry"
            >
                <Typography
                    variant="h1"
                    sx={{
                        textAlign: 'center',
                        padding: {
                            xs: '2rem 0rem 2rem 0rem'
                        },
                        fontSize: {
                            xs: '18px',
                            sm: '24px',
                            md: '32px',
                            lg: '48px'
                        },
                        color: 'var(--text-color)',
                        wordBreak: 'break-word',

                    }}
                >
                    {albumTitle}
                </Typography>
                {photos.map((photo: PhotoProps) => (
                    <ImageListItem key={photo._id}>
                        <Modal onOpen={() => toggleModal(photo._id)}>
                            <Card className={styles.image}>
                                <CardMedia
                                    component="img"
                                    alt={photo.title}
                                    height="100%"
                                    image={`${urlFor(
                                        photo.image.asset._ref,
                                    )}?w=800&h=800&fit=fill&fill=solid&auto=format&dpr=2`}
                                    loading="lazy"
                                />
                            </Card>
                        </Modal>
                        {showPhoto[photo._id] && (
                            <ModalContent
                                onClose={() => toggleModal(photo._id)}
                                onPrev={onPrev}
                                onNext={onNext}
                            >
                                <Card
                                    sx={{
                                        backgroundColor:
                                            'var(--background-color)',
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        alt={photo.title}
                                        image={`${urlFor(
                                            photos[currentPhotoIndex].image
                                                .asset._ref,
                                        )}?w=700&h=700&fit=fill&fill=solid&auto=format&dpr=2`}
                                        loading="lazy"
                                        sx={{
                                            width: 'auto',
                                            maxWidth: '100%',
                                            maxHeight: isMobile ? 'auto' : '90vh',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </Card>
                            </ModalContent>
                        )}
                    </ImageListItem>
                ))}
            </ImageList>
            <Button
                sx={{
                    color: 'var(--text-color)',
                    fontSize: '3rem',
                    padding: '1rem 0rem 1rem 0rem',
                }}
                onClick={() => {
                    window.location.href = '/portfolio';
                }}>
                <ExitToAppIcon />
            </Button>
        </Stack>
    );
};

export default PhotoList;
