import './global.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { NavBar, Home, Footer, About, Album, PhotoList } from './components';

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: 'var(--background-color)',
        },
    },
    typography: {
        fontFamily: 'OstrichSans-Black, sans-serif',
        h1: {
            fontFamily: 'inherit',
            fontWeight: 'bolder',
        },
        body2: {
            fontWeight: 'lighter',
            marginBottom: '4%'
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <NavBar />
                <Routes>
                    <Route path="/" Component={Home} />
                    <Route path="/home" Component={Home} />
                    <Route path="/about" Component={About} />
                    <Route path="/portfolio" Component={Album} />
                    <Route path="/portfolio/:albumTitle" Component={PhotoList} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
