import * as React from 'react';
import styles from './Home.styles.module.css';
import { Card, CardMedia } from '@mui/material';
import { useMediaQuery } from '@mui/material';


const Home = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <Card className={styles.container} sx={{ boxShadow: 0 }}>
            <CardMedia
                component="img"
                alt={'Passeig de Isabell Barcelona'}
                sx={{
                    height: isMobile ? '65vh' : '90vh',
                    objectFit: 'scale-down',
                }}
                src={require('../../assets/home_bkg.jpg')}
                loading="lazy"
            />
        </Card>
    );
};

export default Home;
