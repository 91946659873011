const navLinks = [
    {
        id: 'about',
        title: 'About',
    },
    {
        id: 'portfolio',
        title: 'Portfolio',
    },
];

export { navLinks };
