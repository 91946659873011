import styles from './Modal.styles.module.css';
import EjectIcon from '@mui/icons-material/Eject';
import { useMediaQuery } from '@mui/material';
import { useSwipeable } from 'react-swipeable';

interface ModalProps {
    onOpen: () => void;
    children: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = ({ onOpen, children }) => {
    return <div onClick={onOpen}> {children}</div>;
};

interface ModalContentProps {
    onClose: () => void;
    children: React.ReactNode;
    onPrev: () => void;
    onNext: () => void;
}

export const ModalContent: React.FC<ModalContentProps> = ({
    onClose,
    children,
    onPrev,
    onNext,
}) => {
    const isSmallScreen = useMediaQuery('(max-width:900px)');
    const swipeHandlers = useSwipeable({
        onSwipedLeft: onNext,
        onSwipedRight: onPrev,
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    const modalContentClass = isSmallScreen
    ? `${styles.modalContent} ${styles.smallScreenContent}`
    : styles.modalContent;

    return (
        <div className={styles.modal} {...swipeHandlers}>
            <div className={modalContentClass}>
                {!isSmallScreen ? (
                    <>
                        <button className={styles.arrowBox} onClick={onPrev}>
                            <div className={styles.arrowBackBox}>
                                <EjectIcon
                                    className={`${styles.arrowIcon} ${styles.arrowBackIcon}`}
                                    sx={{
                                        color: 'var(--text-color)',
                                        fontSize: '4rem',
                                    }}
                                />
                            </div>
                        </button>
                        <div
                            className={styles.imageContainer}
                            onClick={onClose}
                        >
                            {children}
                        </div>
                        <button className={styles.arrowBox} onClick={onNext}>
                            <div className={styles.arrowForwardBox}>
                                <EjectIcon
                                    className={`${styles.arrowIcon} ${styles.arrowForwardIcon}`}
                                    sx={{
                                        color: 'var(--text-color)',
                                        fontSize: '4rem',
                                    }}
                                />
                            </div>
                        </button>
                    </>
                ) : (
                    <div className={styles.imageContainer} onClick={onClose}>
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};
